// 1. Import the initial variables
@import "node_modules/bulma/sass/utilities/initial-variables";


$blue: #13aabd;

// 3. Set the derived variables
// Use the new pink as the primary color
$primary: $blue;


// 4. Import the rest of Bulma
@import "node_modules/bulma/bulma";
