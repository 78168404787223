$button-color: $grey-darker !default;
$button-background-color: $white !default;
$button-border-color: $grey-lighter !default;

$button-hover-color: $link-hover !default;
$button-hover-border-color: $link-hover-border !default;

$button-focus-color: $link-focus !default;
$button-focus-border-color: $link-focus-border !default;

$button-active-color: $link-active !default;
$button-active-border-color: $link-active-border !default;

$button-link-color: $text !default;
$button-link-hover-background-color: $background !default;
$button-link-hover-color: $text-strong !default;

$button-disabled-background-color: $white !default;
$button-disabled-border-color: $grey-lighter !default;
$button-disabled-shadow: none !default;
$button-disabled-opacity: 0.5 !default;

$button-static-color: $grey !default;
$button-static-background-color: $white-ter !default;
$button-static-border-color: $grey-lighter !default;

$button-shadow-inset: inset 0 1px 2px rgba($black, 0.2) !default;

// The button sizes use mixins so they can be used at different breakpoints
@mixin button-small {
  border-radius: $radius-small;
  font-size: $size-small; }
@mixin button-medium {
  font-size: $size-medium; }
@mixin button-large {
  font-size: $size-large; }

.button {
  @include control;
  @include unselectable;
  background-color: $button-background-color;
  border-color: $button-border-color;
  color: $button-color;
  cursor: pointer;
  justify-content: center;
  padding-left: 0.75em;
  padding-right: 0.75em;
  text-align: center;
  white-space: nowrap;
  strong {
    color: inherit; }
  .icon {
    &,
    &.is-small,
    &.is-medium,
    &.is-large {
      height: 1.5em;
      width: 1.5em; }
    &:first-child:not(:last-child) {
      margin-left: calc(-0.375em - 1px);
      margin-right: 0.1875em; }
    &:last-child:not(:first-child) {
      margin-left: 0.1875em;
      margin-right: calc(-0.375em - 1px); }
    &:first-child:last-child {
      margin-left: calc(-0.375em - 1px);
      margin-right: calc(-0.375em - 1px); } }
  // States
  &:hover,
  &.is-hovered {
    border-color: $button-hover-border-color;
    color: $button-hover-color; }
  &:focus,
  &.is-focused {
    border-color: $button-focus-border-color;
    // box-shadow: 0 0 0.5em rgba($button-focus-border-color, 0.25)
    color: $button-focus-color; }
  &:active,
  &.is-active {
    border-color: $button-active-border-color;
    box-shadow: $button-shadow-inset;
    color: $button-active-color; }
  // Colors
  &.is-link {
    background-color: transparent;
    border-color: transparent;
    color: $button-link-color;
    text-decoration: underline;
    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      background-color: $button-link-hover-background-color;
      color: $button-link-hover-color; }
    &[disabled] {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none; } }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      border-color: transparent;
      color: $color-invert;
      &:hover,
      &.is-hovered {
        background-color: darken($color, 2.5%);
        border-color: transparent;
        color: $color-invert; }
      &:focus,
      &.is-focused {
        border-color: transparent;
        box-shadow: 0 0 0.5em rgba($color, 0.25);
        color: $color-invert; }
      &:active,
      &.is-active {
        background-color: darken($color, 5%);
        border-color: transparent;
        box-shadow: $button-shadow-inset;
        color: $color-invert; }
      &[disabled] {
        background-color: $color;
        border-color: transparent;
        box-shadow: none; }
      &.is-inverted {
        background-color: $color-invert;
        color: $color;
        &:hover {
          background-color: darken($color-invert, 5%); }
        &[disabled] {
          background-color: $color-invert;
          border-color: transparent;
          box-shadow: none;
          color: $color; } }
      &.is-loading {
        &:after {
          border-color: transparent transparent $color-invert $color-invert !important; } }
      &.is-outlined {
        background-color: transparent;
        border-color: $color;
        color: $color;
        &:hover,
        &:focus {
          background-color: $color;
          border-color: $color;
          color: $color-invert; }
        &.is-loading {
          &:after {
            border-color: transparent transparent $color $color !important; } }
        &[disabled] {
          background-color: transparent;
          border-color: $color;
          box-shadow: none;
          color: $color; } }
      &.is-inverted.is-outlined {
        background-color: transparent;
        border-color: $color-invert;
        color: $color-invert;
        &:hover,
        &:focus {
          background-color: $color-invert;
          color: $color; }
        &[disabled] {
          background-color: transparent;
          border-color: $color-invert;
          box-shadow: none;
          color: $color-invert; } } } }
  // Sizes
  &.is-small {
    @include button-small; }
  &.is-medium {
    @include button-medium; }
  &.is-large {
    @include button-large; }
  // Modifiers
  &[disabled] {
    background-color: $button-disabled-background-color;
    border-color: $button-disabled-border-color;
    box-shadow: $button-disabled-shadow;
    opacity: $button-disabled-opacity; }
  &.is-fullwidth {
    display: flex;
    width: 100%; }
  &.is-loading {
    color: transparent !important;
    pointer-events: none;
    &:after {
      @include loader;
      @include center(1em);
      position: absolute !important; } }
  &.is-static {
    background-color: $button-static-background-color;
    border-color: $button-static-border-color;
    color: $button-static-color;
    box-shadow: none;
    pointer-events: none; } }
